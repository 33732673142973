<template>
  <div class="Pbl">
    <img src="../assets/Financial PBL course.jpg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style>
img {
  width: 100%;
  height: 100%;
}
</style>